<template>
  <div>
    <h2 class="mb-4">Инвайты</h2>

    <v-sheet class="pa-4" elevation="3">
      <div class="text-h6">Загрузить</div>

      <v-file-input v-model="file" class="mt-4" label="Справочник" placeholder="Выбрать файл">
        <template v-slot:selection="{ text }">
          <v-chip small label color="primary">
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>

      <div class="text-right">
        <v-btn color="accent" :loading="loading" @click="upload">Обновить</v-btn>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { toFormData } from "~/core/helpers";

export default {
  data() {
    return {
      loading: false,

      file: null
    };
  },
  methods: {
    async upload() {
      if (!this.file) return this.$snackbar.warn("Файл не выбран");

      if (this.loading) return;

      this.loading = true;

      try {
        await this.$axios.$post("invites", toFormData({ file: this.file }));

        this.file = null;

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e, ["file"]).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
